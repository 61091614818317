<!--
  - Copyright (C) 2019. Archimedes Exhibitions GmbH,
  - Saarbrücker Str. 24, Berlin, Germany
  -
  - This file contains proprietary source code and confidential
  - information. Its contents may not be disclosed or distributed to
  - third parties unless prior specific permission by Archimedes
  - Exhibitions GmbH, Berlin, Germany is obtained in writing. This applies
  - to copies made in any form and using any medium. It applies to
  - partial as well as complete copies.
  -->

<template>
  <b-container fluid class="timeline">
    <b-row class="tl-navbar">
      <b-col>
        <div v-if="activeView === 'day'">
          <b-form-datepicker
            :hide-header="true"
            id="example-datepicker"
            v-model="pickerVal" class="mb-2"
            :locale="$root.$i18n.locale"
            label-help=""
          ></b-form-datepicker>
          <b class="ml-3">{{ $t('ems.timeline.details.week')}} {{currentWeek}} </b>
        </div>
        <div v-if="activeView === 'week'">
          <h2>
            <b-icon-timeline-fill></b-icon-timeline-fill>
            {{ month }} {{ year }}
          </h2>
          <b>{{ $t('ems.timeline.details.week')}} {{currentWeek}} </b>
        </div>
      </b-col>
      <b-col class="text-right">
        <b-button-group v-if="activeView === 'day'">
          <b-button variant="outline-dark" v-on:click="prevDate">
            <b-icon-chevron-left></b-icon-chevron-left>
          </b-button>
          <b-button variant="outline-dark" v-on:click="resetDate">{{ $t('ems.timeline.details.today')}}
          </b-button>
          <b-button variant="outline-dark" v-on:click="nextDate">
            <b-icon-chevron-right></b-icon-chevron-right>
          </b-button>
        </b-button-group>

        <b-button-group v-if="activeView === 'week'">
          <b-button variant="outline-dark" v-on:click="prevWeek">
            <b-icon-chevron-left></b-icon-chevron-left>
          </b-button>
          <b-button variant="outline-dark" v-on:click="resetWeek">{{ $t('ems.timeline.details.today')}}
          </b-button>
          <b-button variant="outline-dark" v-on:click="nextWeek">
            <b-icon-chevron-right></b-icon-chevron-right>
          </b-button>
        </b-button-group>
        <b-button-group class="mx-3">
          <b-button variant="outline-dark" :pressed="activeView === 'day'"
                    v-on:click="activeView= 'day'">{{ $t('ems.timeline.details.day')}}
          </b-button>
          <b-button variant="outline-dark" :pressed="activeView === 'week'"
                    v-on:click="activeView='week'">{{ $t('ems.timeline.details.week')}}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <!-- BEGIN: Track timelines-->
    <timeline-track :ref="data.uuid"
                    v-for="data in tracks" :key="data.uuid"
                    v-bind:metaData="data"
                    v-bind:driver="parseDriverInfo(data.client_uuid)"
                    v-bind:dateContext="trackDateContext"
                    v-bind:working_hours="working_hours"
                    v-bind:view="activeView"
                    v-bind:clientHealth="trackHealth[data.client_uuid]"
    >
    </timeline-track>
    <!-- END: Track timelines-->
  </b-container>
</template>

<script>
  import moment from 'moment'
  import Vue from 'vue'

  export default {
    name: 'Timeline',
    props: [
      'tracks',
      'clients',
      'drivers',
      'driverAliases'
    ],
    components: {
      TimelineTrack: () => import('@/components/Track.vue')
    },
    data () {
      return {
        activeView: 'day',
        today: moment(),
        dateContext: moment(),
        contextCache: moment(),
        working_hours: null,
        updateTimer: null,
        pickerVal: moment().format('YYYY-MM-DD'),
        trackHealth_: {},
        destroyed: false,
        retryInterval: null
      }
    },
    watch: {
      activeView () {
        this.dateContext = moment(this.contextCache)
        console.debug(this.contextCache)
      },
      pickerVal () {
        this.dateContext = moment(this.pickerVal)
        this.contextCache = this.dateContext
      },
      clients () {
        for (let track of this.tracks) {
          this.updateClientStatus(track.client_uuid)
        }
      },
      tracks () {
        if (this.clients) {
          for (let track of this.tracks) {
            this.updateClientStatus(track.client_uuid)
          }
        }
      }
    },
    computed: {
      year () {
        return this.dateContext.format('Y')
      },
      month () {
        return this.dateContext.format('MMMM')
      },
      currentWeek () {
        return this.dateContext.isoWeek()
      },
      initialDate () {
        return this.today.get('date')
      },
      initialWeek () {
        return this.today.isoWeek()
      },
      trackDateContext () {
        return moment(this.dateContext)
      },
      trackHealth () {
        let dict = {}
        for (let client of this.clients) {
          let message = 'UNKNOWN'
          let variant = 'secondary'

          if (this.trackHealth_[client.clientId]) {
            if (this.trackHealth_[client.clientId] === this.$driverManager.HEALTH_STATUS_SERVING) {
              message = 'SERVING'
              variant = 'success'
            } else if (this.trackHealth_[client.clientId] === this.$driverManager.HEALTH_STATUS_NOT_SERVING) {
              message = 'NOT SERVING'
              variant = 'warning'
            } else if (this.trackHealth_[client.clientId] === this.$driverManager.HEALTH_STATUS_SERVICE_UNKNOWN) {
              message = 'SERVICE UNKNOWN'
              variant = 'danger'
            }
          }

          dict[client.clientId] = { 'message': message, 'variant': variant }
        }
        return dict
      }
    },
    methods: {
      parseDriverInfo (clientId) {
        let c = this.getClient(clientId)
        return c ? this.getDriverClient(c) : ''
      },
      getClient (clientId) {
        return this.$props.clients.find(d => d.clientId === clientId)
      },
      getDriverClient (client) {
        if (client.clientId in this.driverAliases) {
          return this.$props.drivers.find(
            d => d.alias === this.driverAliases[client.clientId]
          )
        }
        return this.$props.drivers.find(d => d.driverId === client.driverId)
      },
      nextDate () {
        this.dateContext = moment(this.dateContext).add(1, 'day')
        this.contextCache = this.dateContext
        this.updateDatepicker()
      },
      prevDate () {
        this.dateContext = moment(this.dateContext).subtract(1, 'day')
        this.contextCache = this.dateContext
        this.updateDatepicker()
      },
      resetDate () {
        this.dateContext = moment(this.today.date(this.initialDate))
        this.contextCache = this.dateContext
        this.updateDatepicker()
      },
      nextWeek () {
        this.dateContext = moment(this.dateContext).add(1, 'week')
        this.contextCache = this.dateContext
        this.updateDatepicker()
      },
      prevWeek () {
        this.dateContext = moment(this.dateContext).subtract(1, 'week')
        this.contextCache = this.dateContext
        this.updateDatepicker()
      },
      resetWeek () {
        this.dateContext = moment(this.today.isoWeek(this.initialWeek))
        this.contextCache = this.dateContext
        this.updateDatepicker()
      },
      updateDatepicker () {
        this.pickerVal = this.dateContext.format('YYYY-MM-DD')
      },
      updateNowOnTracks () {
        this.tracks.forEach((t) => {
          if (this.$refs[t.uuid]) {
            this.$refs[t.uuid][0].updateNowIndication()
          }
        })
      },
      getTimeline () {
        return new Promise((resolve) => {
          fetch(
            Vue.prototype.$TIMELINE_MONGODB + '/timelines/' + this.$route.params.cal_uuid,
            { headers: this.$keycloakmanager.getTokenHeader() }
          )
            .then((resp) => resp.json())
            .then((response) => {
              resolve(response.msg.result)
            })
        })
      },
      updateClientStatus (clientId) {
        let client = this.getClient(clientId)
        if (!client) {
          return
        }
        let driver = this.getDriverClient(client)
        let statusActionName = driver.getHealthEventName()
        if (statusActionName) {
          this.$driverManager.sendAction(
            statusActionName,
            client,
            driver,
            null
          ).then(status => {
            if (status) {
              this.$set(
                this.trackHealth_,
                clientId,
                driver.getHealthStatusString(status.status)
              )
            }
          }).catch(err => {
            console.error(err)
          })
        }
      },
      onClientUpdate (data) {
        if (data.status.isHealthStatus) {
          this.$set(this.trackHealth_, data.clientId, data.status.uiStatus)
        }
      }
    },
    mounted () {
      this.$driverManager.$on('client-update', this.onClientUpdate)
      this.getTimeline().then(c => {
        this.working_hours = c.working_hours
        this.updateTimer = setInterval(this.updateNowOnTracks, 500)
      })

      this.$root.$on('sync-tracks', (zoomScale) => {
        this.tracks.forEach(t => {
          if (this.$refs[t.uuid]) {
            this.$refs[t.uuid][0].syncZoom(zoomScale)
          }
        })
      })
    },
    beforeDestroy () {
      this.$driverManager.$off('client-update', this.onClientUpdate)
      clearInterval(this.updateTimer)
      this.$root.$off('sync-tracks')
    }
  }
</script>

<style lang="scss" scoped>
  .b-form-btn-label-control.form-control {
    border: 0;
    font-size: 24px;
  }
</style>
